import { mergeParams } from '@procsea/common/utils'

import { useUserSellerCompanyId } from 'src/contexts/userContext'
import {
  GenerateQueriesArgs,
  UsePaginatedEntitiesQueryArgs,
  generateQueries,
} from 'src/queries/queryGenerator'
import { SellerProductInstanceQueryParams, SellerProductInstanceWithStock } from 'src/types'

export const SPIS_WITH_STOCKS_ENDPOINT = 'seller_product_instances/list-with-stocks'
export const SPIS_WITH_STOCKS_QUERY_KEY = 'sellerProductInstances/listWithStocks'

const config: GenerateQueriesArgs<
  SellerProductInstanceWithStock,
  SellerProductInstanceQueryParams
> = {
  endpoint: SPIS_WITH_STOCKS_ENDPOINT,
  queryKey: SPIS_WITH_STOCKS_QUERY_KEY,
}

const { usePaginatedEntitiesQuery } = generateQueries(config)

type UsePaginatedSpisWithStocksQueryArgs<TData> = UsePaginatedEntitiesQueryArgs<
  SellerProductInstanceWithStock,
  Partial<SellerProductInstanceQueryParams>,
  TData
>

export const usePaginatedSpisWithStocksQuery = <TData = SellerProductInstanceWithStock>(
  args: UsePaginatedSpisWithStocksQueryArgs<TData> = {}
) => {
  const sellerCompanyId = useUserSellerCompanyId()

  const params: UsePaginatedSpisWithStocksQueryArgs<TData> = {
    queryOptions: { enabled: !!sellerCompanyId },
    queryParams: { sellerCompanyId },
  }

  return mergeParams(usePaginatedEntitiesQuery)(params)(args)
}
